<template lang="pug">
.inspect-robot
  h1 Inspeção

  .inspect-robot__info
    Icon.robot(
      :texts="takeTexts"
      label="Alterar Foto"
      :file="image"
      :icon="['fas', 'robot']"
      changeImage
      @emit="doSetImage"
      @click4='doNavigateToInspectRobot(1, index)'
    )

    .inspect-robot__info--inspections
      .static
        Check(
          :value="{text: 'Estática'}"
          :checked="getCurrentRobot.static_inspection"
          @change="doSetStatic"
        )
        ul.list
          li Chave de Ligar
          li Baterias
          li LED
          li Suporte
          li Trava de arma
          li Rádio

      .dynamic
        Check(
          :value="{text: 'Dinâmica'}"
          :checked="getCurrentRobot.dynamic_inspection"
          @change="doSetDynamic"
        )
        ul.list
          li Locomoção
          li Arma

  Button.inspect-robot__save(
    text="Salvar robô"
    size="big"
    @click="doSave"
  )
</template>

<script>
import {
  mapGetters,
  mapActions
} from 'vuex'

import {
  Icon,
  Check,
  Button
} from '@/components/atoms'

export default {
  name: 'InspectRobot',
  data: () => ({
    image: '',
    static: false,
    dynamic: false
  }),
  mounted () {
    this.static = this.getCurrentRobot.static_inspection
    this.dynamic = this.getCurrentRobot.dynamic_inspection
    this.image = this.getCurrentRobot.image
  },
  components: {
    Icon,
    Check,
    Button
  },
  computed: {
    ...mapGetters('robots', [
      'getCurrentRobot'
    ]),
    ...mapGetters('categories', [
      'getCategories'
    ]),
    ...mapGetters('users', [
      'getUser'
    ]),
    takeTexts () {
      if (this.getCategories.length) {
        const category = this.getCategories.find(category => category.id === this.getCurrentRobot.category_id).name
        return [
          { text: this.getCurrentRobot.name },
          { text: category },
          { text: this.getCurrentRobot.team_name }
        ]
      }
      return ['']
    }
  },
  methods: {
    ...mapActions('robots',
      [
        'setUpdateRobot',
        'setRobotInspection',
        'setRobotImage'
      ]),
    doSetImage (e) {
      this.image = e
    },
    doSetStatic (e) {
      this.static = e.checked
    },
    doSetDynamic (e) {
      this.dynamic = e.checked
    },
    doSave () {
      const robot = {
        robot_id: this.getCurrentRobot.id,
        user_id: this.getUser.id,
        event_id: 1,
        dynamic_inspection: this.dynamic,
        static_inspection: this.static
      }
      this.setRobotInspection(robot)
      this.setRobotImage({ robot_id: this.getCurrentRobot.id, image: this.image.split('base64,')[1] })
    }
  },
  filters: {},
  watch: {}
}
</script>

<style lang="sass" scoped>
  .inspect-robot
    &__info
      font-size: 14px
      display: flex
      .robot
        margin: 8px
      &--inspections
        margin: 16px
    &__save
      margin: 16px
</style>
